import React, {useCallback} from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import qs from 'query-string';
import {ChevronLeft} from '@styled-icons/feather/ChevronLeft';
import AdminArrayTable from '../../Components/AdminArrayTable';

const spec = {
  name: '分店活動設定',
  collection: 'store_event_settings',
  path: '/admin/store_event_settings',
};

const defaultValue = {
  event: '',
  store: '',
  redeem_rules: [],
  // {name: '', is_alternative: true, is_redeem_completed: true}
};
const defaultRedeemRule = {
  name: '',
  is_alternative: false,
  is_redeem_completed: false,
};

export default function AdminStoreEventSetting(props) {
  const {location} = props;
  const {store_id, action, id} = qs.parse(location.search) || {};
  const [events, setEvents] = React.useState([]);
  const [record, setRecord] = React.useState(defaultValue);
  const [event, setEvent] = React.useState();
  const [store, setStore] = React.useState();

  const getEvents = React.useCallback(async () => {
    AppActions.setLoading(true);
    try {
      let resp = await JStorage.fetchAllDocuments('event', {});
      setEvents(resp);
    } catch (error) {
      console.warn(error);
    }
    AppActions.setLoading(false);
  }, []);

  React.useEffect(() => {
    getEvents();
  }, [getEvents]);

  const getRecord = React.useCallback(
    async (event_id) => {
      try {
        let resp = await JStorage.fetchOneDocument(spec.collection, {
          event: event_id,
          store: store_id,
        });
        return resp;
      } catch (error) {
        console.warn(error);
      }
    },
    [store_id, action],
  );

  const getOthers = React.useCallback(
    async (storeEventSetting) => {
      try {
        if (id && store_id && events.length > 0) {
          let event = events.find((e) => e.id === id);
          setEvent(event);

          // auto full redeem_rules, for lazy
          let redeem_rules = [];
          for (let rule of event?.redeem_rules?.gift_rules || []) {
            let storeRule = (storeEventSetting?.redeem_rules || []).find(
              (r) => r.name === rule.name,
            );

            if (!storeRule) {
              redeem_rules.push({...defaultRedeemRule, name: rule.name});
            } else {
              redeem_rules.push({...storeRule});
            }
          }
          setRecord((prev) => ({...prev, redeem_rules: redeem_rules}));

          let stores = await AppActions.fetchStores({allowAll: true});
          let store = stores.find((s) => s.id === store_id);
          setStore(store);
        }
      } catch (error) {
        console.warn(error);
      }
    },
    [id, store_id, events],
  );

  React.useEffect(() => {
    if (id) {
      (async () => {
        AppActions.setLoading(true);
        let resp = await getRecord(id);
        setRecord(resp || defaultValue);
        getOthers(resp);
        AppActions.setLoading(false);
      })();
    }
  }, [id, getRecord, getOthers]);

  const submit = async () => {
    AppActions.setLoading(true);
    try {
      if (action === 'create') {
        await JStorage.createDocument(spec.collection, {
          ...record,
          store: store_id,
          event: id,
        });
        Ant.message.success('創建成功');
        AppActions.navigate(
          `${spec.path}?store_id=${store_id}&id=${id}&action=detail`,
        );
      } else if (action === 'detail') {
        await JStorage.updateDocument(spec.collection, {id: record.id}, record);
        Ant.message.success('更新成功');
      }
    } catch (error) {
      console.warn(error);
      Ant.message.error('發生錯誤');
    }
    AppActions.setLoading(false);
  };

  const giftMap = React.useMemo(() => {
    return event?.redeem_rules?.gift_rules.reduce((sum, x) => {
      sum[x.name] = x.gift.name;
      return sum;
    }, {});
  }, [event]);

  const alternativeGiftMap = React.useMemo(() => {
    return event?.redeem_rules?.gift_rules.reduce((sum, x) => {
      sum[x.name] = x.alternative_gift.name;
      return sum;
    }, {});
  }, [event]);

  return action ? (
    <Wrapper>
      <Row>
        <BackButton
          className="resource-back-button"
          onClick={() =>
            AppActions.navigate(`${spec.path}?store_id=${store_id}`)
          }>
          <ChevronLeft size={24} />
          <div className="back-btn-text">{'返回'}</div>
        </BackButton>
        {action === 'create' ? (
          <h1 style={{marginLeft: 10}}>{`創建${spec.name}`}</h1>
        ) : (
          <h1 style={{marginLeft: 10}}>{`${spec.name}詳情`}</h1>
        )}
      </Row>

      <Ant.Row>
        <Ant.Col span={2} className="label">
          分店
        </Ant.Col>
        <Ant.Col span={10}>
          <Ant.Input value={store?.name || record.store} disabled />
        </Ant.Col>

        <Ant.Col span={2} className="label">
          活動
        </Ant.Col>
        <Ant.Col span={10}>
          <Ant.Input value={event?.name || record.event} disabled />
        </Ant.Col>
      </Ant.Row>

      {event?.type === 'DRAGON_BALL_STAMP' ? (
        <React.Fragment>
          <h2>進階設定</h2>

          <AdminArrayTable
            dataSource={record.redeem_rules}
            columns={[
              {
                title: '對應規則名稱（對應活動規則設定）',
                key: 'name',
                span: 5,
                render: (_, item, index) => item.name,
                // <Ant.Input
                //   value={item.name}
                //   onChange={(e) => {
                //     let value = e.target.value;
                //     let redeem_rules = [...record.redeem_rules];
                //     redeem_rules[index] = {
                //       ...redeem_rules[index],
                //       name: value,
                //     };
                //     setRecord((prev) => ({...prev, redeem_rules}));
                //   }}
                // />
              },
              {
                title: '贈品',
                key: 'gift',
                span: 5,
                render: (_, item) => giftMap[item.name],
              },
              {
                title: '替代贈品',
                key: 'alternative_gift',
                span: 5,
                render: (_, item) => alternativeGiftMap[item.name],
              },
              {
                title: '贈品兌換完畢',
                key: 'is_redeem_completed',
                span: 4,
                render: (_, item, index) => (
                  <Ant.Checkbox
                    checked={item.is_redeem_completed}
                    type="number"
                    min={0}
                    onChange={(e) => {
                      let value = !e.target.checked;
                      let redeem_rules = [...record.redeem_rules];
                      redeem_rules[index] = {
                        ...redeem_rules[index],
                        is_redeem_completed: value,
                      };
                      setRecord((prev) => ({...prev, redeem_rules}));
                    }}
                  />
                ),
              },
              {
                title: '使用替代贈品（需勾選兌換完畢）',
                key: 'is_alternative',
                span: 5,
                render: (_, item, index) => (
                  <Ant.Checkbox
                    checked={item.is_alternative}
                    type="number"
                    min={0}
                    onChange={(e) => {
                      let value = !e.target.checked;
                      let redeem_rules = [...record.redeem_rules];
                      redeem_rules[index] = {
                        ...redeem_rules[index],
                        is_alternative: value,
                      };
                      setRecord((prev) => ({...prev, redeem_rules}));
                    }}
                  />
                ),
              },
            ]}
            onChange={(redeem_rules) =>
              setRecord((prev) => ({...prev, redeem_rules}))
            }
            defaultRecord={defaultValue.redeem_rules[0]}
            enableSort={false}
            enableAdd={false}
            enableDelete={false}
          />
          <div style={{marginVertical: 10, color: '#ccc'}}>
            已自動帶入規則、贈品名稱
          </div>
          <Row style={{marginTop: 10}}>
            <div style={{flex: 1}} />
            <Ant.Button type="primary" onClick={submit}>
              確認
            </Ant.Button>
          </Row>
        </React.Fragment>
      ) : (
        <div style={{marginTop: 20}}>此活動沒有相關分店設定</div>
      )}
    </Wrapper>
  ) : (
    <Wrapper>
      <h1>{spec.name}</h1>
      <Ant.Table
        dataSource={events.filter((x) => x.type === 'DRAGON_BALL_STAMP')}
        columns={[
          {
            title: '活動',
            render: (record) => (
              <Ant.Button
                type="link"
                onClick={async () => {
                  AppActions.setLoading(true);
                  let resp = await getRecord(record.id);

                  AppActions.navigate(
                    `${spec.path}?action=${resp ? 'detail' : 'create'}&id=${
                      record.id
                    }&store_id=${store_id}`,
                  );
                  AppActions.setLoading(false);
                }}>
                {record.name}
              </Ant.Button>
            ),
          },
        ]}
        rowKey="id"
      />
      <Ant.Button onClick={() => getEvents()}>重新整理</Ant.Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;

  & h1 {
    font-size: 32px;
  }

  & h2 {
    margin: 10px 0;
  }

  .ant-row {
    border-left: solid 1px #ccc;
    border-top: solid 1px #ccc;
  }
  .ant-row + .ant-row {
    border-top: 0;
  }

  .ant-col {
    padding: 6px;
    display: flex;
    align-items: center;
    border-right: solid 1px #ccc;
    border-bottom: solid 1px #ccc;
  }

  .label {
    background-color: #ffe6a1;
  }

  .rev-table .ant-table-thead > tr > th {
    background-color: #ffe6a1;
    font-weight: 400;
  }
  .rev-table.ant-table.ant-table-middle .ant-table-footer {
    padding: 0;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  border-bottom: 1px solid transparent;
  padding: 5px 10px 5px 0px;
  background-color: transparent;
  cursor: pointer;

  & > .back-btn-text {
    font-size: 1.2rem;
  }

  &:hover {
    border-bottom: 1px solid black;
  }
`;
